import type { AccountStatus } from "./types";
import { getSession } from "~/services/session";

export const retrieveAccountStatus = async (): Promise<AccountStatus> => {
  const session = await getSession();

  console.log("retrieveAccountStatus firing");
  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/account/status`,
    {
      headers: {
        Authorization: `Bearer ${session?.token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    // TODO
    throw new Error("Error retrieving account status.");
  }

  const data: AccountStatus = await response.json();
  return data;
};
