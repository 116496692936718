import type { ListOrderSummary } from "./types";
import { getSession } from "~/services/session";

export const retrieveOrders = async (
  days: number = 365
): Promise<ListOrderSummary> => {
  const session = await getSession();

  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/account/orders?days=${days}`,
    {
      headers: {
        Authorization: `Bearer ${session?.token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    // TODO
    throw new Error("Error retrieving orders.");
  }

  const data: ListOrderSummary = await response.json();
  return data;
};
