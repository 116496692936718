import { cache } from "@solidjs/router";
import { retrieveAccountProfile } from "../roma-api/account";
import type { AccountProfileFilters } from "../roma-api/account/types";

export const useAccountProfile = cache(
  async (
    filters?: AccountProfileFilters,
    options?: { cleanShipTos: boolean }
  ) => retrieveAccountProfile(filters, options),
  "account-profile"
);
