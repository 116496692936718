import type { Document } from "./types";
import { getSession } from "~/services/session";

export const retrieveAccountDocuments = async (
  options: {
    groupByCategory: boolean;
  } = { groupByCategory: false }
): Promise<Document[] | DocumentsByCategory> => {
  const session = await getSession();

  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/account/documents`,
    {
      headers: {
        Authorization: `Bearer ${session?.token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    // TODO
    throw new Error("Error retrieving account documents.");
  }

  const data: Document[] = await response.json();

  if (options?.groupByCategory) {
    return groupByCategory(data);
  }

  return data;
};

const groupByCategory = (data: Document[]) => {
  const list = data.reduce((memo, doc) => {
    for (const tag in doc.Categories) {
      // If group doesn't exist, create it:
      if (!memo[doc.Categories[tag]]) {
        memo[doc.Categories[tag]] = {
          LastUpdated: doc.LastUpdated,
          List: [doc],
        };
      } else {
        memo[doc.Categories[tag]].List.push(doc);
        memo[doc.Categories[tag]].LastUpdated = Math.max(
          memo[doc.Categories[tag]].LastUpdated,
          doc.LastUpdated
        );
      }
    }
    return memo;
  }, {} as DocumentsByCategory);
  return list;
};

export type DocumentsByCategory = {
  [key: string]: {
    LastUpdated: number;
    List: Document[];
  };
};
