import { action, json, redirect, reload, revalidate } from "@solidjs/router";
import { submitLogin } from "../roma-api/account";
import { getSession, getIsPartner, revalidateOnSession } from "../session";
import { listCarts } from "../cart/useListCarts";

export const loginAction = action(
  async (username: string, password: string) => {
    "use server";
    const resp = await submitLogin(username, password);

    // TODO: Test behaviour on shop page sign in/ sign out, require all revalidation/manual revalidation to get prices on prods to show?

    // revalidate(getSession.key) <-- did not prevent other revalidations, but did force validation of provided cache
    // return resp; <-- all caches are revalidated..maybe good? /shop page should refresh and show prices..

    return json(resp, { revalidate: revalidateOnSession }); // <-- revalidates *only* the provided cache

    // if ('Token' in resp && resp.Token !== "") {
    //   const session = await getSession();
    //   await session.update((d) => {
    //     d.token = resp.Token;
    //     d.first_name = resp.FirstName;
    //     d.last_login = new Date();
    //   });
    //   return resp
    // }

    // ? OR
    // return json(resp)

    // ? OR handle the 3 login responses?

    // if ('Token' in resp && resp.Token !== "" ) {
    //   // setSession stuff..
    // } else if ('Transfer' in resp) {
    //   // redirect to a transfer form? or this should be handled in component, and add fields to form?
    // } else if ('Reset' in resp) {
    //   // redirect
    //   redirect(`/forgot-password?code=${resp.Code}&email=${resp.Email}`, {revalidate: "nothing??defaultIsToRevalideAllCachedFunctions"})
    // }
  }
);
