import type { AccountProfile, AccountProfileFilters } from "./types";
import { getParamStringFromObject } from "~/utils/helpers";
import { getSession } from "~/services/session";

export const retrieveAccountProfile = async (
  filters?: AccountProfileFilters,
  options: { cleanShipTos: boolean } = { cleanShipTos: false }
): Promise<AccountProfile> => {
  const session = await getSession();

  const params = filters ? getParamStringFromObject(filters) : "";

  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/account/profile${params}`,
    {
      headers: {
        Authorization: `Bearer ${session?.token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    // TODO
    throw new Error("Error retrieving account profile.");
  }

  const data: AccountProfile = await response.json();

  // strip away the 'CUSTOM' ShipTo that is necessary on checkout/order
  if (options.cleanShipTos && data.ShipTos) {
    data.ShipTos = data.ShipTos.filter((shipTo) => shipTo.Name !== "CUSTOM");
  }

  return data;
};
